import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => window.Telegram.WebApp.themeParams.bg_color || '#ffffff'};
    color: ${(props) => window.Telegram.WebApp.themeParams.text_color || '#000000'};
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
