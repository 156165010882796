import React from 'react';
import styled from 'styled-components';
import { Button, Badge } from '@mui/material';

const ButtonContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const InvoicesButton = ({ invoiceCount }) => {
  return (
    <ButtonContainer>
      <Badge badgeContent={invoiceCount} color="error">
        <Button variant="contained" color="primary" onClick={() => window.location.href = '/#/invoices'}>
          Счета
        </Button>
      </Badge>
    </ButtonContainer>
  );
};

export default InvoicesButton;
