import React from 'react';
import styled from 'styled-components';

const BalanceContainer = styled.div`
  text-align: center;
  background-color: ${() => window.Telegram.WebApp.themeParams.secondary_bg_color || '#ffffff'};
  color: ${() => window.Telegram.WebApp.themeParams.text_color || '#000000'};
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
`;

const BalanceValue = styled.h1`
  font-size: 48px;
`;

const Balance = ({ balance, userName }) => (
  <BalanceContainer className="balance-container">
    <h2>Баланс</h2>
    <BalanceValue>{balance.tenge} ₸</BalanceValue>
    <BalanceValue>{balance.rub} ₽</BalanceValue>
  </BalanceContainer>
);

export default Balance;
