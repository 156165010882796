import React from 'react';
import styled from 'styled-components';

const ExtraButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const ExtraButton = styled.button`
  background-color: ${() => window.Telegram.WebApp.themeParams.button_color || '#ff5e5e'};
  color: ${() => window.Telegram.WebApp.themeParams.button_text_color || '#ffffff'};
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s;
  font-size: 16px;

  &:hover {
    background-color: ${() => window.Telegram.WebApp.themeParams.button_hover_color || '#ff3333'};
  }
`;

const ExtraButtons = () => {
  const tg = window.Telegram.WebApp;
  
  return (
    <ExtraButtonContainer>
      <ExtraButton onClick={() => window.open('http://t.me/payKZTbot/app', '_blank')}>Что это?</ExtraButton>
      <ExtraButton onClick={() => tg.openLink('https://services.verigram.cloud/hf2/01J4JXFRF4MWW0M04VFQH9BR0T-F/')}>Пройти Верификацию</ExtraButton>
      <ExtraButton onClick={() => window.open('https://t.me/shoptengetestbot', '_blank')}>Пример магазина</ExtraButton>
    </ExtraButtonContainer>
  );
};

export default ExtraButtons;
