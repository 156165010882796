import React, { useEffect, useState } from 'react';
import GlobalStyle from './styles/GlobalStyle';
import { BrowserRouter as Router, Routes, Route, HashRouter  } from 'react-router-dom';
import Home from './pages/Home';
import PaymentConfirmation from './components/PaymentConfirmation';
import InvoicesList from './components/InvoicesList';

function App() {
  const [theme, setTheme] = useState(window.Telegram.WebApp.themeParams);

  useEffect(() => {
    window.Telegram.WebApp.expand();
    setTheme(window.Telegram.WebApp.themeParams);
  }, []);

  return (
    <HashRouter>
      <GlobalStyle theme={theme} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/payment/:paymentToken" element={<PaymentConfirmation />} />
        <Route path="/invoices" element={<InvoicesList />} /> {/* Добавляем маршрут для списка счетов */}
      </Routes>
    </HashRouter>
  );
}

export default App;
