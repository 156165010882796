  import React from 'react';
  import styled from 'styled-components';
  import { Button } from '@mui/material';
  import SendIcon from '@mui/icons-material/Send';
  import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
  import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

  const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  `;

  const ActionButtons = () => {
    const buttonColor = window.Telegram.WebApp.themeParams.button_color || '#0088cc';
    const buttonTextColor = window.Telegram.WebApp.themeParams.button_text_color || '#ffffff';

    return (
      <ButtonContainer>
        <Button
          variant="contained"
          style={{ backgroundColor: buttonColor, color: buttonTextColor }}
          startIcon={<SendIcon />}
        >
          Отправить
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: buttonColor, color: buttonTextColor }}
          startIcon={<AttachMoneyIcon />}
        >
          Пополнить
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: buttonColor, color: buttonTextColor }}
          startIcon={<SwapHorizIcon />}
        >
          Обменять
        </Button>
      </ButtonContainer>
    );
  };

  export default ActionButtons;
