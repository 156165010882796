import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Стилизация
const InvoiceListContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
`;

const InvoiceCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const InvoiceInfo = styled.p`
  font-size: 18px;
  margin: 5px 0;
  color: ${({ status }) => (status === 'paid' ? '#4CAF50' : '#FF9800')};
`;

const InvoiceDate = styled.p`
  font-size: 14px;
  color: #777;
  margin: 5px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  color: #fff;
  background-color: #0088cc;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #005699;
  }
`;

const BackButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #0088cc;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color 0.3s;
  z-index: 1000;

  &:hover {
    background-color: #006699;
  }
`;

const InvoicesList = () => {
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState('');
  const [telegramId, setTelegramId] = useState(null);
  const navigate = useNavigate();

  const fetchInvoices = (telegramId) => {
    fetch(`https://back.tengetestbot.ru/api/get-invoices?telegramId=${telegramId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при получении счетов');
        }
        return response.json();
      })
      .then((data) => {
        const sortedInvoices = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setInvoices(sortedInvoices);
      })
      .catch((error) => setError('Ошибка при получении счетов: ' + error.message));
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    setTelegramId(tg.initDataUnsafe.user.id);
    fetchInvoices(tg.initDataUnsafe.user.id);
  }, []);

  const confirmPayment = (paymentToken) => {
    fetch(`https://back.tengetestbot.ru/api/payment/confirm/${paymentToken}?telegramId=${telegramId}`, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Ошибка при подтверждении платежа');
        }
        return response.text();  // Изменяем на .text(), если сервер возвращает строку
      })
      .then((message) => {
        alert(message);
        setInvoices((prevInvoices) => {
          return prevInvoices.map((invoice) =>
            invoice.paymentToken === paymentToken
              ? { ...invoice, status: 'paid' }  // Обновляем статус платежа на "paid"
              : invoice
          );
        });
      })
      .catch((error) => {
        alert('Ошибка подтверждения платежа: ' + error.message);
      });
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <InvoiceListContainer>
      <Title>Список счетов</Title>
      {error ? (
        <div>{error}</div>
      ) : (
        invoices.map((invoice, index) => (
          <InvoiceCard key={index}>
            <InvoiceInfo status={invoice.status}>
              Сумма: {invoice.amount} тенге - Статус: {invoice.status}
            </InvoiceInfo>
            <InvoiceDate>Создан: {formatDate(invoice.createdAt)}</InvoiceDate>
            {invoice.status === 'pending' && invoice.paidBy === String(telegramId) && (
              <Button onClick={() => confirmPayment(invoice.paymentToken)}>Подтвердить</Button>
            )}
          </InvoiceCard>
        ))
      )}
      <BackButton onClick={() => navigate(-1)}>Назад</BackButton>
    </InvoiceListContainer>
  );
};

export default InvoicesList;
