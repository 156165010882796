import React, { useEffect, useState } from 'react';
import Balance from '../components/Balance';
import ActionButtons from '../components/ActionButtons';
import InvoicesButton from '../components/InvoicesButton';
import ExtraButtons from '../components/ExtraButtons';
import styled from 'styled-components';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 18px;
  color: ${(props) => props.theme.textColor || '#000'};
  margin-bottom: 20px;
  font-weight: bold;
`;

const Home = () => {
  const [balance, setBalance] = useState({ tenge: 0, rub: 0 });
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [error, setError] = useState('');
  const [name, setName] = useState('User');

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.expand();
    if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
      const telegramId = tg.initDataUnsafe.user.id;
      fetchBalance(telegramId);
      fetchInvoiceCount(telegramId);
      setName(tg.initDataUnsafe.user.first_name);
    } else {
      setError('WebApp API недоступен.');
    }
  }, []);

  const fetchBalance = async (telegramId) => {
    try {
      const response = await fetch(`https://back.tengetestbot.ru/api/getBalance/${telegramId}`);
      if (!response.ok) {
        throw new Error('Ошибка при получении баланса');
      }
      const data = await response.json();
      setBalance({ tenge: data.balanceTenge, rub: data.balanceRub });
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchInvoiceCount = async (telegramId) => {
    try {
      const response = await fetch(`https://back.tengetestbot.ru/api/get-invoice-count/${telegramId}`);
      if (!response.ok) {
        throw new Error('Ошибка при получении количества счетов');
      }
      const data = await response.json();
      setInvoiceCount(data);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <HomeContainer>
      <Title>Ваш Кошелек {name}</Title>
      <Balance balance={balance} />
      <ActionButtons />
      <ExtraButtons />
      <InvoicesButton invoiceCount={invoiceCount} />
      {error && <div className="error">{error}</div>}
    </HomeContainer>
  );
};

export default Home;
