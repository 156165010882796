import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Стили для контейнера
const PaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

// Стили для текста
const PaymentInfo = styled.p`
  font-size: 18px;
  margin: 10px 0;
  font-weight: bold;
`;

// Стили для кнопки подтверждения оплаты
const ConfirmButton = styled.button`
  padding: 10px 30px;
  font-size: 16px;
  color: #ffffff;
  background-color: #28a745;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:active {
    background-color: #1e7e34;
  }
`;

// Стили для кнопки "Назад"
const BackButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: #ffffff;
  background-color: #0088cc;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #006699;
  }
`;

const PaymentConfirmation = () => {
  const { paymentToken } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const navigate = useNavigate(); // Для перехода назад

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await fetch(`https://back.tengetestbot.ru/api/payment/details/${paymentToken}`);
        if (!response.ok) {
          throw new Error('Error fetching payment details');
        }
        const data = await response.json();
        setPaymentDetails(data);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    fetchPaymentDetails();
  }, [paymentToken]);

  const confirmPayment = async () => {
    const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    if (!telegramId) {
      setErrorMessage('Telegram WebApp is not available');
      return;
    }

    try {
      const response = await fetch(`https://back.tengetestbot.ru/api/payment/confirm/${paymentToken}`, {
          method: 'POST',  // Меняем метод на POST
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ telegramId }),  // Отправляем telegramId в теле запроса
      });

      if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Error confirming payment: ${errorText}`);
      }

      setConfirmationStatus('Платеж успешно подтвержден!');
    } catch (error) {
        setErrorMessage(error.message);
    }  
  }

  return (
    <PaymentContainer>
      {errorMessage && <p className="error">{errorMessage}</p>}
      {confirmationStatus ? (
        <h3>{confirmationStatus}</h3>
      ) : (
        paymentDetails && (
          <div>
            <h3>Подтвердите оплату</h3>
            <PaymentInfo>Сумма: {paymentDetails.amount} {paymentDetails.currency}</PaymentInfo>
            <ConfirmButton onClick={confirmPayment}>Подтвердить оплату</ConfirmButton>
          </div>
        )
      )}
      <BackButton onClick={() => navigate(-1)}>Назад</BackButton>
    </PaymentContainer>
  );
};

export default PaymentConfirmation;
